import React, { useState, useRef } from "react";
import TwilioService from "../service/twilioService";
import { Device } from "@twilio/voice-sdk";

const TwilioCall = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [device, setDevice] = useState<Device | null>(null);
  const [connection, setConnection] = useState<any | null>(null);
  const [userState, setUserState] = useState<string>("READY");
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const callStartTimeRef = useRef<number | null>(null);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const [isCalling, setIsCalling] = useState<boolean>(false);
  const [callDuration, setCallDuration] = useState<number>(0);

  const getToken = async (): Promise<string> => {
    // const response = await axios.post<{ token: string }>(
    //   "https://1b0d-103-21-164-198.ngrok-free.app/api/twilio/token",
    //   {
    //     identity: "defaultEfitoUser",
    //   }
    // );
    try {
      const response = await TwilioService.getToken();
      return response;
    } catch (error) {
      return "get token failed";
    }
  };

  const makeCall = async (phoneNumber: string) => {
    if (!phoneNumber) {
      return;
    }
    if (!device) {
      try {
        const token = await getToken();

        const newDevice = new Device(token, {
          logLevel: 1,
          edge: "singapore",
        });

        setDevice(newDevice);

        setIsCalling(true);
        setCallDuration(0);
        callStartTimeRef.current = Date.now();

        const call = await newDevice.connect({
          params: { To: phoneNumber },
          rtcConstraints: { audio: true },
        });

        setConnection(call);

        call.on("accept", () => {
          console.log("Call accepted");

          if (callStartTimeRef.current) {
            timerIntervalRef.current = setInterval(() => {
              setCallDuration(
                Math.floor((Date.now() - callStartTimeRef.current!) / 1000)
              );
            }, 1000);
          }

          setUserState("ON_CALL");
        });

        call.on("disconnect", () => {
          console.log("The call has been disconnected.");
          setConnection(null);
          setIsCalling(false);
          setUserState("READY");
          if (timerIntervalRef.current) {
            clearInterval(timerIntervalRef.current);
          }
        });

        call.on("ringing", () => {
          console.log("call is ringing");
          setUserState("READY");
        });

        call.on("reject", () => {
          console.log("The call was rejected.");
        });

        call.on("error", (error) => {
          console.error("Call error:", error);
          setIsCalling(false);
          setCallDuration(0);
          if (timerIntervalRef.current) {
            clearInterval(timerIntervalRef.current);
          }
        });
      } catch (error) {
        setIsCalling(false);
        console.error("Error making call:", error);
      }
    } else {
      setIsCalling(true);
      const call = await device.connect({
        params: { To: phoneNumber },
        rtcConstraints: { audio: true },
      });

      setConnection(call);
    }
  };

  const hangUpCall = () => {
    if (connection) {
      connection.disconnect();
      setConnection(null);
      setIsCalling(false);
      setCallDuration(0);
    } else {
      console.error("No active call to hang up");
    }
  };

  const muteCall = () => {
    if (connection) {
      console.log("calls muted");
      connection.mute(true);
      setIsMuted(true);
    }
  };

  const unmuteCall = () => {
    if (connection) {
      console.log("calls unmuted");
      connection.mute(false);
      setIsMuted(false);
    }
  };

  return (
    <div className="flex h-screen bg-gradient-to-r from-indigo-500  justify-center items-center">
      <div className="border shadow-2xl 2xsm:w-4/5 sm:w-3/4 md:w-3/4 lg:w-1/2 rounded-lg border-gray-400 flex bg-gray-200 flex-col justify-between items-center w-1/2 h-1/2 py-4">
        <h1 className="text-[25px] font-bold ">Twilio Call App</h1>
        <p>Call Status:- {userState}</p>

        {isCalling && (
          <div>
            <p className="text-[20px]">Call Duration: {callDuration} seconds</p>
          </div>
        )}
        <input
          type="text"
          className="border rounded-md py-3 px-6 border-gray-400"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Enter phone number"
        />

        <div className="flex gap-3">
          {!isCalling && (
            <button
              className="bg-green-600 py-2 px-5 border text-white rounded-md"
              onClick={() => makeCall(phoneNumber)}
            >
              Call
            </button>
          )}
          {connection && (
            <button
              onClick={hangUpCall}
              className="bg-red-600 text-white py-4 px-4 rounded-full"
            >
              Diclne
            </button>
          )}

          <button
            className="bg-stone-700 text-white py-4 px-4 rounded-full"
            onClick={isMuted ? unmuteCall : muteCall}
            disabled={!connection}
          >
            <img
              className="w-[30px] h-[30px]"
              src={isMuted ? "assets/mutemic.png" : "assets/unmutemic.png"}
              alt=""
            />
          </button>

          {/* <button
            className="bg-stone-700 text-white py-4 px-4 rounded-full"
            onClick={unmuteCall}
            disabled={!connection || !isMuted}
          >
            Unmute
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default TwilioCall;
