import { Instance } from "./axiosService";

class TwilioService {
  getToken = async () => {
    const response = await Instance({
      method: "POST",
      url: "/api/twilio/token",
      data: {
        identity: "user",
      },
    });

    const token = response.data.token;
    return token;
  };
}

export default new TwilioService();
